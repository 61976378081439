<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <div class="accout-content clearfix"  style="display:flex">
          <!-- 左边 -->
          <user-left mark="questionnaire" :userId="userId" />
          <!-- 右边 -->
          <a-card :bordered="false" class="card-right" style="width:100%">
              <span style="font-size:18px;font-weight:bold;">rfq信息</span>
              <div class="first">
                <span>rfq标题:</span>
                <span>{{ list.title }}</span>
              </div>
              <div class="second">
                <span>一级分类名称:</span>
                <span>{{ list.categoryOneName }}</span>
              </div>
              <div class="second">
                <span>二级分类名称:</span>
                <span>{{ list.categoryTwoName }}</span>
              </div>
              <div class="second">
                <span>交货地地点:</span>
                <span>{{ list.deliveryAddr }}</span>
              </div>
              <div class="second">
                <span>有效日期:</span>
                <span>{{ list.effectiveDate }}</span>
              </div>
              <div class="second">
                <span>付款方式:</span>
                <span>{{ list.paymentType }}</span>
              </div>
              <div class="second">
                <span>产品关键字:</span>
                <span v-for="(item,index) in list.productKeywords" :key="index">{{ item }}</span>
              </div>
              <div class="second">
                <span>产品名称:</span>
                <span>{{ list.productName }}</span>
              </div>
              <div class="second">
                <span>产品数量:</span>
                <span>{{ list.productNum }}</span>
              </div>
              <div class="second">
                <span>rfq状态:</span>
                <span>{{ list.status }}</span>
              </div>
              <span style="font-size:18px;font-weight:bold;">采购商公司信息</span>
              <div class="second">
                <span>公司名称:</span>
                <span style="width:600px">{{ list.purchaser.companyName }}</span>
              </div>
              <div class="second">
                <span>公司地址:</span>
                <span style="width:600px">{{ list.purchaser.address }}</span>
              </div>
              <div class="second">
                <span>公司所在区域:</span>
                <span style="width:600px">{{ list.purchaser.countryName }}</span>
              </div>
              <span style="font-size:18px;font-weight:bold;">rfq报价信息</span>
              <div v-if="categoryId==1">
                <div v-if="list.quotes == ''"> 
                    <div class="second">
                        <span>rqf报价:</span>
                        <input type="text" v-model="price">
                    </div>
                    <div class="second">
                        <span>rqf描述:</span>
                        <input type="text" v-model="desc">
                    </div>
                    <button class="submit" @click="regsub()">提交</button>
                </div>
                <div v-else>
                    <div v-for="(item,index) in list.quotes" :key="index">
                        <div class="second">
                            <span>公司名称:</span>
                            <span style="width:600px">{{ item.companyName }}</span>
                        </div>
                        <div class="second">
                            <span>描述:</span>
                            <span style="width:600px">{{ item.desc }}</span>
                        </div>
                        <div class="second">
                            <span>价格:</span>
                            <span style="width:600px">{{ item.price }}</span>
                        </div>
                        <div class="second">
                            <span>状态:</span>
                            <span style="width:600px">{{ item.statusText }}</span>
                        </div>
                    </div>
                </div>
              </div>
              <div v-if="categoryId==2">
                  <div v-for="(item,index) in list.quotes" :key="index" class="queot">
                      {{index+1}}、{{item.companyName}} {{item.desc}} {{ item.price }}  
                      <a-button type="primary" @click="checkquote(item.quoteId)" v-if="item.status!=1">选择</a-button>
                  </div>
              </div>
              <div style="height:20px"></div>
              <span style="font-size:18px;font-weight:bold;">rfq附件文件信息</span>
              <div v-for="(item,index) in list.annexes" :key="index" class="queot">
                  <a :href="item.url" :download="item.fileName">{{item.fileName}}</a>
              </div>
          </a-card>
        </div>
      </div>
    </div>
     <!-- 公共尾部 -->
    <Footer></Footer>
  </div>
</template>

<style>
    .main-content .card-right{
        width: 100%;
    }
    .first,.second{
        text-align: left;
    }
    .first>span,.second>span{
        font-size: 16px;
        width: 140px;
        display: inline-block;
    }
    input[type=text]{
      width: 300px;
      border: 1px solid #787878;
      margin-left: 10px;
    }
    .second{
        margin-top: 20px;
    }
    .submit{
        width: 200px;
        height: 40px;
        margin-top: 40px;
        border: 1px solid  #d9d9d9;
        line-height: 40px;
        background: #ff4d4f;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
    }
    .ant-select-selection--single{
        margin-left: 10px;
        border: 1px solid black;
    }
    .ant-upload.ant-upload-select-picture-card{
        margin-left: 10px;
    }
    .ant-calendar-picker{
        margin-left: 10px;
    }
    .queot{
        text-align: left;
        font-size: 16px;
        margin-top: 10px;
    }
    .ant-btn-primary{
        margin-left: 20px;
    }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'ExbitAdd',
  inject: ['reload'], // 注入依赖
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        userId: this.$route.params.userId,
        id: this.$route.params.id,
        list: {
            purchaser: {},
            quotes: {},
            annexes: {}
        },
    }
  },
   mounted () {
    this.getList()
  },
   methods: {
     //获取问卷详情
    getList () {
       this.$common.fetchList('/user/questionnaire/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          console.log(result)
          this.list = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
    // rqf报价添加
    regsub() {
        // if (this.price == '' || this.desc == '') {
        //     this.$layer.alert("请正确填写信息");
        //     return 
        // }
        if (this.price == '') {
           this.$layer.alert("价格不能为空");
          return 
        } else if (this.desc == '') {
           this.$layer.alert("描述不能为空");
          return 
        }
        const params = {
            price: this.price,
            desc: this.desc,
            rfqId: this.id
        }
        this.$common.handlePost('/user/rfq/quote/add', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.reload()
        } else {
            this.$layer.alert(data.msg);
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
    //rfq报价选择 
    checkquote(id) {
      this.$common.fetchList('/user/rfq/quote/choose/'+id, {}).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.reload()
            }
        }).catch(() => {
            this.$notification.error({
            message: '错误',
            description: '请求信息失败，请重试'
            })
        })
     }
   }
}
</script>